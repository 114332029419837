import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'
import Rellax from 'rellax/rellax'

export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            HomeService.header().then(() => {
                HomeService.strateExperiences().then(() => {
                    HomeService.parallax();
                    HomeService.headerHomeLoading();
                    HomeService.scrollReveal();
                })
            })
        }
    }

    static async header() {
        const element = 'div[data-home-banner-slider]'
        if (element.length > 0 && !$('body').hasClass('eco-mode-active')) {
            const swiperKey = 'home_banner_swiper';
            const slideItem = '.header__banner__item > .header__banner__item__thumbnail';

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                slidesPerView: 1,
                spaceBetween: 0,
                speed: 2000,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                keyboard: true,
                loop: false,
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }
    }

    static async strateExperiences() {
        const element = '.strate-experience'
        if (element.length > 0 && !$('body').hasClass('eco-mode-active')) {
            const swiperKey = 'home_experiences_swiper';
            const slideItem = `[data-swiper="${swiperKey}"] > .strate__experiences__card`;

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                slidesPerView: 1.2,
                spaceBetween: 10,
                speed: 1250,
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                keyboard: true,
                loop: false,
                freeMode: {
                    enabled: true,
                },
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    1000: {
                        slidesPerView: 1,
                        spaceBetween: 100,
                    },
                    1400: {
                        slidesPerView: 1,
                        spaceBetween: 140,
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }
    }

    static parallax() {
        const element = $('.home');
        if (
            element.length > 0 &&
            !element.is(':hidden') &&
            !$('body').hasClass('eco-mode-active') &&
            window.matchMedia('(min-width: 1000px)').matches
        ) {
            const rellax = new Rellax('.rellax')
        }
    }

    static headerHomeLoading() {
        const $body = $('body');
        if ( !$body.hasClass('eco-mode-active') && $body.hasClass('home') &&  window.matchMedia('(min-width: 1000px)').matches ) {
            $body.removeClass('hp-reveal-loading');
        }
    }

    static scrollReveal() {
        const ratio = 0.1
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        }

        const handleIntersect = (entries, observer) => {
            for (const entry of entries) {
                if (entry.intersectionRatio >= ratio) {
                    entry.target.classList.remove('reveal')
                    observer.unobserve(entry.target)
                }
            }
        }

        if (!$('body').hasClass('eco-mode-active')) {
            document.documentElement.classList.add('reveal-loaded')
            if (!window.matchMedia('(max-width: 1000px)').matches) {
                const observer = new IntersectionObserver(handleIntersect, options)
                for (const el of $('.reveal')) {
                    observer.observe(el)
                }
            } else {
                for (let i = 1; i <= 10; i++) {
                    for (const el of $(`.reveal-${i}`)) {
                        el.classList.remove(`reveal-${i}`)
                    }
                }
            }
        }
    }
}
